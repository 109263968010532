.App {
  text-align: center;
  align-items: center;
  align-content: center;
  font-family: futuraFont;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: futuraFont;
}

.App-link {
  color: #61dafb;
}

/* input[type=text],
input[type=email], */
select,
textarea {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

select :focus,
textarea :focus {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  border: 1px solid #027FFF;
  border-radius: 4px;
  box-sizing: border-box;
}


.registrationbutton {
  width: 100%;
  /* background: #0175FF; */
  background: linear-gradient(270.17deg, #5A5B63 0.15%, #161617 99.87%);
  color: #FFFFFF;
  padding: 1rem;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: futuraFont;
}

.registrationbutton:hover {
  /* background: #0175FF; */
  background: linear-gradient(270.17deg, #7A7A83 0.15%, #47474B 99.87%);
}
.registrationbutton-co {
  width: 100%;
  /* background: #0175FF; */
  /* background: linear-gradient(270.17deg, #5A5B63 0.15%, #161617 99.87%); */
  background-color: rgb(245,245,245);
  color: #000000;
  padding: 1rem;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.registrationbutton-co:hover {
  /* background: #0175FF; */
  /* background: linear-gradient(270.17deg, #7A7A83 0.15%, #47474B 99.87%); */
  background: rgb(180,180,180,0.8);
}

.select {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.select:focus {
  width: 100%;
  padding: 1rem;
  display: inline-block;
  border: 1px solid #027FFF;
  border-radius: 4px;
  box-sizing: border-box;
}

.divider {
  background-color: #949292;
  height: 2px;
  border: 1px solid #949292;
  width: 100%;
}

.cover-image {
  background-image: url("../public/pexels-lucas-pezeta-2423412_low.jpg");
  background-size: cover;
}

.error {
  color:rgb(211,47,47);
  font-size: 12px;
  margin-left: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.containerStyle {
  width: 100%;
  height: 100%;
  max-height: 56px;
}

.form-control {
  width: 100% !important;
  height: 100%;
}

.form-control:disabled {
  opacity: 0.6 !important;
}

.special-label {
  opacity: 0.6 !important;
}
