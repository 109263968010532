@font-face {
  font-family: futuraFont ;
  src: local("FUTURA\ MEDIUM"),
    url("./assets/FUTURA\ MEDIUM.otf") format("opentype");
}


body {
  margin: 0;
  font-family: futuraFont,apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


